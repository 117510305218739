/**
 * Google Recaptcha
 */

$(document).ready(function() {

    /**
     * Honeypot approach
     */
    $('form').each(function() {
        // Honeypot Field
        $(this).append('<input type="text" name="honeypot_field" style="display:none" tabindex="-1" autocomplete="off">');

        // Timestamp Field
        var formTimestamp = Date.now();
        $(this).append('<input type="hidden" name="form_timestamp" value="' + formTimestamp + '">');

        // JavaScript Check Field (without using id)
        $(this).append('<input type="hidden" name="js_check_field" value="js_enabled">');
    
    });

    const siteKey = '6LcmkmUpAAAAAE2827Mef4O7RczR5lgbhzqxtCpk';

    // Assuming you want to target all forms on the page. If it's a specific form, use a more specific selector.
    $('form').one('focus', 'input, select, textarea', function() {
        var script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=' + siteKey;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        console.log('ReCaptcha Loaded');

        script.onload = function() {
            grecaptcha.ready(function() {
                grecaptcha.execute(siteKey, {action: 'submit'}).then(function(token) {
                    // Ensure the hidden input for the reCAPTCHA response exists or is added to the form
                    var recaptchaResponse = $('#recaptchaResponse');
                    if (recaptchaResponse.length === 0) {
                        recaptchaResponse = $('<input>').attr({
                            type: 'hidden',
                            id: 'recaptchaResponse',
                            name: 'recaptcha_response'
                        }).appendTo('form');
                    }
                    recaptchaResponse.val(token);
                });
            });
        };
    });
});
